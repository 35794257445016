.ContactUs {
    display: flex;
    margin: auto;
}

.ContactUsContainer {
    display: inline-block;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    font-size: 14pt;
}