.Home {
  text-align: center;
  font-family: Poppins;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.HomeHeader {
    color: white;
    background-image: url('../images/chiber_san_background.jpeg');
    height: 100px;
    font-size: 40pt;
    font-weight: 600;
    padding: 20px;
}

@media (max-width: 600px) {
    .HomeHeader {
        font-size: 34pt;
    }
}

.HomeSummary {
    display: flex;
    width: 400px;
    margin: auto;
    padding-top: 20px;
    font-size: 14pt;
}

.HomeInfoSection {
    padding-top: 30px;
    font-size: 16pt;
}

.HomeInfoSectionTitle {
    font-size: 24pt;
    font-weight: 500;
}

