.Layout {
}

.LayoutBar {
    display: flex;
    width: 100%;
    margin: 0px;
    height: 50px;
}

.LayoutLink {
    display: inline-block;
    width: 90px;
}


.LayoutLinkContainer {
    display: inline-block;
    width: 400px;
    margin: auto;
}

.LLink {
    font-size: 12pt;
    color: black;
    text-decoration: none;
}

.LLinkSelected {
    font-size: 12pt;
    color: black;
    font-weight: 500;
}

.LLink:visited {
    color: black;
}

.LLink:hover {
    color: #8F8F8F;
}