.GameHeader {
    padding-bottom: 30px;
    padding-top: 50px;
    font-weight: 600;
    font-size: 30pt;
    text-align: center;
    color: #2d4f58;
}

.GameSectionHeader {
    padding-top: 10px;
    font-size: 24pt;
    text-align: center;
    font-weight: 500;
}

.GameSectionDescription {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 12pt;
}

.GameSectionInfo {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14pt;
}

.GameSection {
    display: inline-block;
    border: thin solid #E0E0E0;
    border-radius: 16px;
    margin-left: 25px;
    margin-right: 25px;
    background-color: #F5F5F5;
    background-opacity: 0.2;
}

.GameSectionComingSoon {
    font-size: 12pt;
}