body {
    font-family: Poppins;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
    font-weight: bold;
    font-size:60pt;
    text-align:center;
}

.summary {
    padding: 20px;
    width: 50%;
    min-width: 400px;
}

.images {
    height: 200px;
    width: 200px;
}

.imageBlock {
    display: inline-block;
    width: 200px;
}

.info {
    display: inline-block;
    padding-top: 10px;
    width: 300px;
    vertical-align: top;
}

.infoBlock {
    padding-top: 10px;
}

.name {
    font-size: 16pt;
}

.title {
    font-size: 14pt;
}
