.Team {
    width: 100%;
}

.TeamHeader {
    padding-bottom: 20px;
    padding-top: 50px;
    font-weight: 500;
    font-size: 30pt;
    text-align: center;
    color: #2d4f58;
}

.TeamImageBlock {
    display: flex;
    justify-content: center;
}

.TeamName, .TeamTitle, .TeamDescription {
    display: flex;
    justify-content: center;
}

.TeamDescription {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 12pt;
}

.TeamName {
    font-size: 24pt;
}

.TeamTitle {
    font-size: 18pt;
}